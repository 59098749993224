import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

export default function ModalPop() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  

  return (
    <>
      <Modal className="" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>you must be over 18 to enter this site</Modal.Body>
        <Modal.Footer>
          <Button
            className="enter-button"
            variant="secondary"
            onClick={handleClose}
          >
            let me in!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
