import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Info from "./Info";
import ModalPop from "./Modal";
import "./scss/index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <ModalPop />
    <img src="/h2yo-logo.svg" alt="H2YO Logo" className="logo" />
    <div className="can-wrapper">
      <App />
    </div>
    <div className="information-wrapper">
      <Info />
    </div>
  </React.StrictMode>,
  rootElement
);
