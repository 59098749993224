import React from "react";
import "./scss/index.css";

export default function Info() {
  return (
    <>
      <div className="info-scroll">scroll for info</div>
      <div className="info-wrap-fix d-flex flex-column justify-content-center align-items-center p-0">
        <div className="label-wrapper">
          <div className="title">
            <img
              src="/label-title.svg"
              alt="Flavour Icon"
              className="label-title-img"
            />
            <p>6% alc/vol</p>
          </div>
          <div className="facts">
            <img
              src="/drink-facts.svg"
              alt="Drink Facts"
              className="drink-facts-img"
            />
          </div>
          <div className="nutri-content">
            <h3 className="nutri-title">nutritional information</h3>
            <p className="mb-2">serving size: 330ml</p>
            <div className="nutrition">
              <img src="/drip.svg" alt="Water Drip" className="drip-img mb-2" />
              <ul>
                <li>energy</li>
                <li>protein</li>
                <li>-gluten</li>
                <li>fat, total</li>
                <li>-saturated</li>
                <li>carbohydrate</li>
                <li>-sugars</li>
                <li>dietary fibre</li>
                <li>sodium</li>
              </ul>
              <ul>
                <li className="mb-2">avg quantity per serving</li>
                <li>698kJ</li>
                <li>0.1g</li>
                <li>0mg</li>
                <li>0.0g</li>
                <li>0.0g</li>
                <li>7.3g</li>
                <li>6.9g</li>
                <li>0.0g</li>
                <li>3mg</li>
              </ul>
              <ul>
                <li className="mb-2">avg quantity per 100ml</li>
                <li>211kJ</li>
                <li>0.0g</li>
                <li>0mg</li>
                <li>0.0g</li>
                <li>0.0g</li>
                <li>2.2g</li>
                <li>2.1g</li>
                <li>0.0g</li>
                <li>1mg</li>
              </ul>
            </div>
          </div>
          <div className="nutri-content">
            <h4 className="">ingredients</h4>
            <p className="mb-0">
              water : vodka : fruit concentrate : natural flavour : citric acid
            </p>
          </div>
        </div>
        <footer class="footer-wrapper">
          <div class="container d-flex flex-column align-items-center justify-content-center">
            <div class="footer-brand mb-2">
              <a href="https://www.instagram.com/h2yo_nz/">
                <img
                  src="/instagram-brands.svg"
                  alt="Instagram Icon"
                  className="insta-icon-img"
                />
              </a>
              <a href="mailto:hello@h2yo.nz">
                <img
                  src="/envelope-solid.svg"
                  alt="Email Icon"
                  className="insta-icon-img"
                />
              </a>
            </div>
            <small class="mb-0 d-none">
              privacy policy &middot; terms + conditions
            </small>
            <small>h2yo © {new Date().getFullYear()}</small>
          </div>
        </footer>
      </div>
    </>
  );
}
